



























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  AdminAction, AdminActionType, UserGetter, UserGetterType, AdminState
} from '@/store/modules/admin/types';
import UsersCardLeftPanel from './UsersCardLeftPanel/UsersCardLeftPanel.vue';
import UsersCardRightPanel from './UsersCardRightPanel/UsersCardRightPanel.vue';

const admin = namespace('admin');

@Component({
  components: {
    UsersCardLeftPanel,
    UsersCardRightPanel,
  },
})
/**
 * @group Users Card
 * Users tab content
 */
export default class UsersCard extends Vue {
  selectedLeftPanelTab: 'groups' | 'users' = 'users'

  hasGroups: boolean = false

  @admin.State
  selectedGroup!: AdminState['selectedGroup'];

  @admin.Action(AdminAction.FetchUsers)
  fetchUsers!: AdminActionType['FETCH_USERS'];

  @admin.Action(AdminAction.FetchGroups)
  fetchGroups!: AdminActionType['FETCH_GROUPS'];

  @admin.Action(AdminAction.FetchAvailableRoles)
  fetchAvailableRoles!: AdminActionType['FETCH_AVAILABLE_ROLES'];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  @admin.Action(AdminAction.FetchGroupRoles)
  FetchGroupIdRoles!: AdminActionType['FETCH_GROUP_ROLES'];

  get permissionCheck() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'users');
    return getPermissions.write;
  }

  async beforeMount() {
    this.fetchUsers();
    this.fetchAvailableRoles();
    await this.fetchGroups();
    this.hasGroups = true;
  }
}
