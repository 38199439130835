













import {
  Vue, Component, Prop, Watch
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Tab } from '@/types/config';
import AsyncLoading from '@/components/base/AsyncLoading.vue';
import AsyncError from '@/components/base/AsyncError.vue';
import { AdminState } from '@/store/modules/admin/types';

type RightPanelTab = 'competences' | 'users' | 'userSettings';

const competencesTab: any = () => ({
  component: import('./UsersCardRightPanelCompetences/UsersCardRightPanelCompetences.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const usersTab: any = () => ({
  component: import('./UsersCardRightPanelUsers/UsersCardRightPanelUsers.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const userSettingsTab: any = () => ({
  component: import('./UsersCardRightPanelUserSettings/UsersCardRightPanelUserSettings.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const admin = namespace('admin');

@Component({
  components: {
    competencesTab,
    usersTab,
    userSettingsTab
  }
})
/**
 * @group Users Card
 * Users tab right panel
 */
export default class UsersCardRightPanel extends Vue {
  @Prop({ required: true, type: String }) leftPanelTab!: 'users' | 'groups'

  selectedTab: RightPanelTab = 'userSettings';

  cancelCompetences = true;

  key = 0;

  get currentKey() {
    if (!this.selectedGroup?.id && !this.selectedUser?.id) {
      return 'refresh';
    }
    return this.selectedGroup ? this.selectedGroup.id : this.selectedUser?.id;
  }

  @admin.State
  selectedUser!: AdminState['selectedUser']

  @admin.State
  selectedGroup!: AdminState['selectedGroup'];

  @Watch('leftPanelTab')
  onleftPanelTabChange(newVal: 'groups' | 'users') {
    if (newVal === 'users') {
      this.selectedTab = 'userSettings';
    } else {
      this.selectedTab = 'competences';
    }
  }

  get tabs(): Tab<RightPanelTab>[] {
    if (this.leftPanelTab === 'users') {
      return [
        { name: this.$t('main.settings'), value: 'userSettings' },
      ];
    }
    return [
      { name: this.$tc('main.competence', 2), value: 'competences' },
      { name: this.$tc('main.user', 2), value: 'users' },
    ];
  }

  get currentTab() {
    if (this.selectedTab === 'competences') {
      return 'competencesTab';
    }
    if (this.selectedTab === 'users') {
      return 'usersTab';
    }
    return 'userSettingsTab';
  }

  onClickChild() {
    this.cancelCompetences = !this.cancelCompetences;
  }

  addUserToGroup() {
    this.cancelCompetences = !this.cancelCompetences;
  }
}
