











import { Component, Vue, Prop } from 'vue-property-decorator';
import { Tab } from '@/types/config';
import AsyncLoading from '@/components/base/AsyncLoading.vue';
import AsyncError from '@/components/base/AsyncError.vue';

type LeftPanelTab = 'users' | 'groups';

const groupsTab: any = () => ({
  component: import('./UsersCardLeftPanelGroups/UsersCardLeftPanelGroups.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const usersTab: any = () => ({
  component: import('./UsersCardLeftPanelUsers/UsersCardLeftPanelUsers.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

@Component({
  components: {
    groupsTab,
    usersTab,
  }
})
/**
 * @group Users Card
 * Users tab left panel with two tabs: Users and Groups
 */
export default class UsersCardLeftPanel extends Vue {
  @Prop({ required: true, type: String }) readonly selectedTab!: LeftPanelTab;

  get tabs(): Tab<LeftPanelTab>[] {
    return [
      { name: this.$tc('main.user', 2), value: 'users' },
      { name: this.$tc('main.group', 2), value: 'groups' },
    ];
  }

  get currentTab() {
    return this.selectedTab === 'users' ? 'usersTab' : 'groupsTab';
  }
}
